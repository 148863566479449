import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { setPagesState } from 'root/utils/createPage';

type InitPageActionsEventListenerArgs = {
  editorSDK: FlowEditorSDK;
};

export const initPageActionsEventListener = async ({ editorSDK }: InitPageActionsEventListenerArgs) => {
  await editorSDK.addEventListener('pageDuplicated', async (event) => {
    await setPagesState({ editorSDK });
  });
};
