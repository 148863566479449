import type { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { BiReporter } from 'root/utils/BiReporter';
import { isMobileViewport, setWidgetProps } from 'root/utils/commonUtils';
import { MENU_PRESET_ID_TO_PRESET_NAME_MAP, MENU_PRESET_ID_TO_PRESET_NAME_MOBILE_MAP } from 'root/utils/widgetPresets';
import { resetWidgetsDesignVariables } from './presetChangedEventListener.utils';

export const initPresetChangedEventListener = async ({
  editorSDK,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) => {
  await editorSDK.addEventListener('globalDesignPresetChanged', async (event) => {
    const { preset: presetId, componentRef } = event.detail;
    const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    const isMobile = await isMobileViewport(editorSDK);
    const TOKEN = '';
    const PRESET_ID_TO_NAME_MAP = isMobile
      ? MENU_PRESET_ID_TO_PRESET_NAME_MOBILE_MAP
      : MENU_PRESET_ID_TO_PRESET_NAME_MAP;
    type PresetId = keyof typeof PRESET_ID_TO_NAME_MAP;
    const isLayoutCustomizationExperimentEnabled = flowAPI.experiments.enabled(
      'specs.restaurants.menus-layout-customization'
    );

    const chosenPreset = PRESET_ID_TO_NAME_MAP[presetId as PresetId];

    const [parentRefComponent] = await editorSDK.components.getAncestors(TOKEN, {
      componentRef,
    });

    if (presetId && parentRefComponent) {
      await editorSDK.application.appStudioWidgets.changePreset(TOKEN, {
        componentRef: parentRefComponent,
        stylePresetId: presetId,
        layoutPresetId: presetId,
      });

      isLayoutCustomizationExperimentEnabled &&
        (await resetWidgetsDesignVariables(editorSDK, parentRefComponent, chosenPreset));

      if (!isMobile) {
        await setWidgetProps(editorSDK, componentRef, {
          preset: chosenPreset,
        });
      }

      biReporter.reportMenuLayoutPickBi(chosenPreset);
    }
  });
};
