import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import type { EditorType } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK, TFunction, ComponentRef, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { openMenuElementsPanel, openColumnsElementsPanel } from 'root/panels/ElementsPanel/elementsActions';
import { BiReporter } from 'root/utils/BiReporter';
import {
  MENU_ELEMENTS_ORIGIN,
  MENU_ELEMENTS_PANEL,
  LABEL_STYLE_ORIGIN,
  LABEL_STYLE_PANEL,
  MENU_SETTINGS_ORIGIN,
  MENU_SETTINGS_PANEL,
  SECTION_ELEMENTS_ORIGIN,
  SECTION_ELEMENTS_PANEL,
  MENU_LAYOUT_ORIGIN,
  MENU_LAYOUT_PANEL,
  MENU_LAYOUT_CUSTOMIZATION_ORIGIN,
  MENU_LAYOUT_CUSTOMIZATION_PANEL,
} from 'root/utils/bi';
import type { ELEMENT_DATA_BLACK_LIST_MAP } from 'root/panels/ElementsPanel/consts';
import { PANEL_IDS, MENU_COMPONENT_REF_ID } from 'root/utils/consts';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { openManageMenuDashboardPanel } from 'root/utils/openManageMenuDashboardPanel';
// eslint-disable-next-line no-restricted-imports
import blocksWidgetDescriptor from '../../blocks-widget-descriptor.json';
import { openMenuSettingsPanel, openSettingsPanel } from './gfppEventListener.utils';
import { LAYOUT_CUSTOMIZATION_PANEL_NAME, LAYOUT_PANELS_NAMES } from 'root/components/Menu/consts';
import { isMobileViewport } from 'root/utils/commonUtils';

type InitGfppEventListenerArgs = {
  editorSDK: FlowEditorSDK;
  editorType: EditorType;
  flowAPI: EditorScriptFlowAPI;
  fedopsLogger: FedopsLogger;
};

const TOKEN = 'token';

export const initGfppEventListener = async ({
  flowAPI,
  editorSDK,
  editorType,
  fedopsLogger,
}: InitGfppEventListenerArgs) => {
  const t = flowAPI.translations.t;
  const panelApiFactory = new PanelsApiFactory();
  const panelsApi = await panelApiFactory.createPanelsApi({
    editorSDK,
    editorType,
    essentials: flowAPI.essentials,
  });

  // TODO: remove when the editor can take app revision from url
  const appRevision = Object.values(blocksWidgetDescriptor)[0].revision.toString();
  const openBlocksPanelWrapper = async (panelName: string, componentRef: ComponentRef, title?: string) => {
    await panelsApi.openBlocksPanel(panelName, componentRef, {
      overrides: {
        revision: appRevision,
        title,
      },
    });
  };

  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    const experiments = flowAPI.experiments;
    const isLabelLayouterWidgetExperimentEnabled = experiments.enabled('specs.restaurants.labelsLayouterWidget');
    const usePanelsTranslation = experiments.enabled('specs.restaurants.menus-bob-panels-translation');
    const isExpandMenuSettingsExperimentEnabled = experiments.enabled('specs.restaurants.menus-expand-settings-panel');

    await biReporter.init(editorSDK);
    const isMobile = await isMobileViewport(editorSDK);

    switch (id) {
      case PANEL_IDS.menuLayoutCustomization: {
        const title = usePanelsTranslation ? t('layout-customization-panel.header-title') : undefined;

        biReporter.reportMenuWidgetPanelBi(MENU_LAYOUT_CUSTOMIZATION_ORIGIN, MENU_LAYOUT_CUSTOMIZATION_PANEL);
        void openBlocksPanelWrapper(LAYOUT_CUSTOMIZATION_PANEL_NAME, componentRef, title);
        break;
      }
      case PANEL_IDS.changeMenuLayout: {
        biReporter.reportMenuWidgetPanelBi(MENU_LAYOUT_ORIGIN, MENU_LAYOUT_PANEL);
        const getCurrentPanelName = () => {
          return LAYOUT_PANELS_NAMES[isMobile ? 'mobile' : 'desktop'];
        };

        const title = usePanelsTranslation ? t('layout-panel.header-title.label') : undefined;
        const layoutPanelName = getCurrentPanelName();

        void openBlocksPanelWrapper(layoutPanelName, componentRef, title);
        break;
      }
      case PANEL_IDS.changeColumnsLayout: {
        void openBlocksPanelWrapper('Sections Layout', componentRef);
        break;
      }
      case PANEL_IDS.manageMenus: {
        openManageMenuDashboardPanel(editorSDK);
        break;
      }
      case PANEL_IDS.columnsManageMenus: {
        openManageMenuDashboardPanel(editorSDK);
        break;
      }
      case PANEL_IDS.menuElementsPanel: {
        fedopsLogger.openMenuElementsPanelStarted();
        const preset = await editorSDK.document.application.appStudioWidgets.getPreset(TOKEN, {
          componentRef,
        });

        biReporter.reportMenuWidgetPanelBi(MENU_ELEMENTS_ORIGIN, MENU_ELEMENTS_PANEL);

        void openMenuElementsPanel(
          editorSDK,
          componentRef,
          t as TFunction,
          () => {
            biReporter.reportElementCheckboxAction('menu');
          },
          fedopsLogger.openMenuElementsPanelEnded,
          preset.layout as keyof typeof ELEMENT_DATA_BLACK_LIST_MAP,
          isLabelLayouterWidgetExperimentEnabled
        );
        break;
      }
      case PANEL_IDS.labelStyle: {
        biReporter.reportSectionWidgetPanelBi(LABEL_STYLE_ORIGIN, LABEL_STYLE_PANEL);

        void openBlocksPanelWrapper('Label Style', componentRef);
        break;
      }
      case PANEL_IDS.settings: {
        biReporter.reportMenuWidgetPanelBi(MENU_SETTINGS_ORIGIN, MENU_SETTINGS_PANEL);
        if (isExpandMenuSettingsExperimentEnabled) {
          openSettingsPanel(editorSDK, componentRef, fedopsLogger, t as TFunction);
        } else {
          openMenuSettingsPanel(
            editorSDK,
            componentRef,
            fedopsLogger,
            t as TFunction,
            isExpandMenuSettingsExperimentEnabled
          );
        }
        break;
      }
      case PANEL_IDS.menuSettings: {
        openMenuSettingsPanel(
          editorSDK,
          componentRef,
          fedopsLogger,
          t as TFunction,
          isExpandMenuSettingsExperimentEnabled
        );
        break;
      }
    }
  });

  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    const experiments = flowAPI.experiments;
    const isLabelLayouterWidgetExperimentEnabled = experiments.enabled('specs.restaurants.labelsLayouterWidget');
    await biReporter.init(editorSDK);

    switch (id) {
      case PANEL_IDS.columnsElementsPanel: {
        fedopsLogger.openColumnsElementsPanelStarted();
        const parentsRef = await editorSDK.document.components.getAncestors('', { componentRef });
        const menuComponentRef = parentsRef.find((compRef) => compRef.id === MENU_COMPONENT_REF_ID);
        const preset =
          menuComponentRef &&
          (await editorSDK.document.application.appStudioWidgets.getPreset(TOKEN, {
            componentRef: menuComponentRef,
          }));

        biReporter.reportSectionWidgetPanelBi(SECTION_ELEMENTS_ORIGIN, SECTION_ELEMENTS_PANEL);

        void openColumnsElementsPanel(
          editorSDK,
          componentRef,
          t as TFunction,
          () => {
            biReporter.reportElementCheckboxAction('section');
          },
          fedopsLogger.openColumnsElementsPanelEnded,
          preset?.layout as keyof typeof ELEMENT_DATA_BLACK_LIST_MAP,
          isLabelLayouterWidgetExperimentEnabled
        );
        break;
      }
    }
  });
};
