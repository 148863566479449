import one_column_preset_image from 'root/assets/images/preset_panel_1_col.jpg';
import two_column_preset_image from 'root/assets/images/preset_panel_2_col.jpg';
import three_column_preset_image from 'root/assets/images/preset_panel_3_col.jpg';
import sbs_preset_image from 'root/assets/images/preset_panel_sbs.jpg';
import strip_column_preset_image from 'root/assets/images/preset_panel_strip.jpg';
import grid_preset_image from 'root/assets/images/preset_panel_grid.jpg';
import mobile_cards_column_preset_image from 'root/assets/images/mobile_preset_panel_cards.jpg';
import mobile_center_column_preset_image from 'root/assets/images/mobile_preset_panel_center.jpg';
import mobile_left_column_preset_image from 'root/assets/images/mobile_preset_panel_left.jpg';
import mobile_strip_column_preset_image from 'root/assets/images/mobile_preset_panel_strip.jpg';
import type { WidgetPreset } from '@wix/platform-editor-sdk';
import {
  CARD_MOBILE_PRESET_ID,
  GRID_PRESET_ID,
  LIST_1_COLUMN_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
  LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
  LIST_2_COLUMN_PRESET_ID,
  LIST_3_COLUMN_PRESET_ID,
  MENU_WIDGET_PRESET_IDS,
  MENU_WIDGET_PRESET_IDS_MOBILE,
  SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
} from './consts';
import type { TFunction } from '@wix/yoshi-flow-editor';

export const MENU_PRESET_ID_TO_PRESET_NAME_MAP = {
  [LIST_1_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listOneColumn,
  [LIST_2_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listTwoColumn,
  [LIST_3_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listThreeColumn,
  [LIST_1_COLUMN_WITH_IMAGE_PRESET_ID]: MENU_WIDGET_PRESET_IDS.listOneColumnWithImage,
  [SIDE_BY_SIDE_1_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS.sideBySideOneColumn,
  [GRID_PRESET_ID]: MENU_WIDGET_PRESET_IDS.grid,
};

export const MENU_PRESET_ID_TO_PRESET_NAME_MOBILE_MAP = {
  [CARD_MOBILE_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.cards,
  [LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.list2And3Column,
  [LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.listOneColumnWithImage,
  [LIST_1_COLUMN_PRESET_ID]: MENU_WIDGET_PRESET_IDS_MOBILE.center,
};

export const getMenuWidgetPresets = (t: TFunction): WidgetPreset[] => [
  {
    id: LIST_1_COLUMN_PRESET_ID,
    src: one_column_preset_image,
    name: t('layout-panel.center-layout.label'),
  },
  {
    id: SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
    src: sbs_preset_image,
    name: t('layout-panel.sbs-layout.label'),
  },
  {
    id: LIST_2_COLUMN_PRESET_ID,
    src: two_column_preset_image,
    name: t('layout-panel.two-columns-layout.label'),
  },
  {
    id: LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
    src: strip_column_preset_image,
    name: t('layout-panel.strip-layout.label'),
  },
  {
    id: LIST_3_COLUMN_PRESET_ID,
    src: three_column_preset_image,
    name: t('layout-panel.three-columns-layout.label'),
  },
  {
    id: GRID_PRESET_ID,
    src: grid_preset_image,
    name: t('layout-panel.grid-layout.label'),
  },
];

export const getMenuWidgetPresetsMobile = (t: TFunction): WidgetPreset[] => [
  {
    id: LIST_1_COLUMN_PRESET_ID,
    src: mobile_center_column_preset_image,
    name: t('layout-panel.center-layout.label'),
  },
  {
    id: LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
    src: mobile_left_column_preset_image,
    name: t('layout-panel.mobile.left-layout.label'),
  },
  {
    id: CARD_MOBILE_PRESET_ID,
    src: mobile_cards_column_preset_image,
    name: t('layout-panel.mobile.cards-layout.label'),
  },
  {
    id: LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
    src: mobile_strip_column_preset_image,
    name: t('layout-panel.strip-layout.label'),
  },
];
