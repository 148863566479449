import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MENUS_SHOWCASE_APP_DEF_ID, MENUS_SHOWCASE_BM_URL } from './consts';

export const openManageMenuDashboardPanel = (editorSDK: FlowEditorSDK) => {
  editorSDK.editor
    .openDashboardPanel(MENUS_SHOWCASE_APP_DEF_ID, {
      url: MENUS_SHOWCASE_BM_URL,
      closeOtherPanels: false,
    })
    .then(async () => {
      await editorSDK.document.application.livePreview.refresh('', {
        shouldFetchData: true,
        source: '',
      });
      await editorSDK.document.application.reloadManifest();
    });
};
