import type { WidgetDesignVariables } from 'root/types';
import { MENU_WIDGET_PRESET_IDS, MENU_WIDGET_PRESET_IDS_MOBILE } from './consts';

export const WIDGETS = { MENU: 'menu', COLUMNS: 'columns', SECTIONS: 'sections', ITEMS: 'items' };

const widgetsDesignVars: Record<string, WidgetDesignVariables> = {
  [WIDGETS.MENU]: {
    menuLeft: {
      value: 20,
      unit: 'px',
    },
    menuTopandBottom: {
      value: 40,
      unit: 'px',
    },
    menuRight: {
      value: 20,
      unit: 'px',
    },
  },

  [WIDGETS.COLUMNS]: {
    columnsSpacing: {
      value: 0,
      unit: 'px',
    },
  },

  [WIDGETS.SECTIONS]: {
    sectionsSpacing: {
      value: 60,
      unit: 'px',
    },
  },
  [WIDGETS.ITEMS]: {
    itemsGridRows: {
      value: 0,
      unit: 'px',
    },
    itemsTitleTop: {
      value: 0,
      unit: 'px',
    },
  },
};

const createWidgetDesignVar = (defaultVars: WidgetDesignVariables, overrides: WidgetDesignVariables) => {
  return Object.fromEntries(Object.entries(defaultVars).map(([key, value]) => [key, { ...value, ...overrides[key] }]));
};

export const DEFAULT_DESIGN_VARS_BY_PRESET = {
  [MENU_WIDGET_PRESET_IDS.listOneColumn]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {
      itemsTitleTop: {
        value: 20,
        unit: 'px',
      },
    }),
  },
  [MENU_WIDGET_PRESET_IDS.listTwoColumn]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {
      columnsSpacing: {
        value: 80,
        unit: 'px',
      },
    }),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {}),
  },
  [MENU_WIDGET_PRESET_IDS.listThreeColumn]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {
      columnsSpacing: {
        value: 60,
        unit: 'px',
      },
    }),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {}),
  },
  [MENU_WIDGET_PRESET_IDS.sideBySideOneColumn]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {
      menuLeft: { value: 0, unit: 'px' },
      menuTopandBottom: { value: 0, unit: 'px' },
    }),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {}),
  },
  // Strip
  [MENU_WIDGET_PRESET_IDS.listOneColumnWithImage]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {
      menuLeft: { value: 0, unit: 'px' },
      menuRight: { value: 0, unit: 'px' },
    }),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {}),
  },
  [MENU_WIDGET_PRESET_IDS.grid]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {
      itemsGridRows: {
        value: 46,
        unit: 'px',
      },
      itemsTitleTop: {
        value: 12,
        unit: 'px',
      },
    }),
  },
  // mobile presets
  [MENU_WIDGET_PRESET_IDS_MOBILE.center]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {
      itemsTitleTop: {
        value: 20,
        unit: 'px',
      },
    }),
  },
  // left
  [MENU_WIDGET_PRESET_IDS_MOBILE.list2And3Column]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {}),
  },
  // Strip
  [MENU_WIDGET_PRESET_IDS_MOBILE.listOneColumnWithImage]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {
      menuRight: {
        value: 0,
        unit: 'px',
      },
      menuLeft: {
        value: 0,
        unit: 'px',
      },
    }),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {}),
  },
  [MENU_WIDGET_PRESET_IDS_MOBILE.cards]: {
    [WIDGETS.MENU]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.MENU], {}),
    [WIDGETS.COLUMNS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.COLUMNS], {}),
    [WIDGETS.SECTIONS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.SECTIONS], {}),
    [WIDGETS.ITEMS]: createWidgetDesignVar(widgetsDesignVars[WIDGETS.ITEMS], {
      itemsGridRows: {
        value: 40,
        unit: 'px',
      },
      itemsTitleTop: {
        value: 12,
        unit: 'px',
      },
    }),
  },
};

export const isEqualDesignVariables = (defaultValues: DesignVariables, currentValues: DesignVariables): boolean => {
  const keys1 = Object.keys(defaultValues);

  return keys1.every(
    (key) => currentValues.hasOwnProperty(key) && defaultValues[key].value === currentValues[key].value
  );
};
