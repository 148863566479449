import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import type { WidgetBuilder, FlowEditorSDK, EditorScriptFlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { MENU_WIDGET_COMPONENT_IDS, DESIGN_PANEL_ARTICLE_ID } from 'root/utils/consts';
import { getMenuDesignPanelTabs } from 'root/utils/designPanelTabs';
import {
  setWidgetDesignTabs,
  configureWidgetTextDesignGfpp,
  disableWidgetComponentSelection,
  getRole,
} from 'root/utils/manifestUtils';
import { setMenuWidgetGfpp } from './utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;
  const experiments = flowAPI.experiments;
  const isExpandMenuSettingsExperimentEnabled = experiments.enabled('specs.restaurants.menus-expand-settings-panel');
  const isNewPresetPanelExperimentEnabled = experiments.enabled('specs.restaurants.menus-new-preset-panel');
  const isLayoutCustomizationExperimentEnabled = experiments.enabled('specs.restaurants.menus-layout-customization');
  const isAddLabelsDesignTabsExperimentEnabled = experiments.enabled('specs.restaurants.addLabelsDesignTabs');
  const isAddSectionDividerDesignTabsExperimentEnabled = experiments.enabled(
    'specs.restaurants.addSectionDividerDesignTab'
  );

  widgetBuilder.set({
    displayName: t('widget.menu.display-name'),
    nickname: 'MenuWidget',
  });

  widgetBuilder.behavior().set({ removable: false });
  setMenuWidgetGfpp({
    desktopGfppBuilder: widgetBuilder.gfpp(),
    mobileGfppBuilder: widgetBuilder.gfpp('mobile'),
    isExpandMenuSettingsExperimentEnabled,
    isLayoutCustomizationExperimentEnabled,
    isNewPresetPanelExperimentEnabled,
    t,
  });

  disableWidgetComponentSelection(widgetBuilder, MENU_WIDGET_COMPONENT_IDS.columns);

  widgetBuilder.configureConnectedComponents(getRole(MENU_WIDGET_COMPONENT_IDS.navigationBar), (componentBuilder) => {
    componentBuilder.set({ displayName: t('menu.horizontalMenu-element.name') });
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('design-panel.title'),
      customHelpId: DESIGN_PANEL_ARTICLE_ID,
    });

    configureWidgetTextDesignGfpp({
      widgetBuilder,
      editorSDK,
      t,
      textComponents: [
        { id: MENU_WIDGET_COMPONENT_IDS.menuTitle, label: t('menu.menuTitle-element.name') },
        { id: MENU_WIDGET_COMPONENT_IDS.menuDescription, label: t('menu.menuDescription-element.name') },
      ],
    });

    const menuDesignPanelTabs = getMenuDesignPanelTabs(
      isAddLabelsDesignTabsExperimentEnabled,
      isAddSectionDividerDesignTabsExperimentEnabled
    );

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

    setWidgetDesignTabs(widgetDesignTabsBuilder, menuDesignPanelTabs, t);
  });
};
